import React from "react"

import styles from "./footer.module.scss"

export default function Footer() {
  return (
    <div className={styles.footer}>
      {/*<h1>This is a footer.</h1>*/}
    </div>
  );
}
